(function () {
    var head = document.getElementsByTagName('head')[0];

    var host = 'https://api2.dentist-plus.com/';

    var css = document.createElement('link');
    css.type = 'text/css';
    css.rel = 'stylesheet';
    css.href = host+"css/iframe.css";
    head.appendChild(css);

    document.addEventListener('click',function(e){
        if(e.target && e.target.classList.contains('dentist-plus-widget-btn')) {
            var dataset = e.target.dataset;
            showWidget(dataset.formId, dataset.lang, "iframe", dataset.opened);
        }
    });

    function showWidget(formId, lang = 'ru', additional_class = "iframe", opened = false) {

        var body = document.getElementsByTagName('body')[0];
        var wrap = document.getElementsByClassName('dentist-iframe-wrap');
        var url_part = 'widget/form?formId='+formId;
        var get_params = '&lang='+lang+'&iframe=1';
        if (wrap.length > 0) {
            wrap[0].style.display = 'block';
        } else {
            if(opened) {
                var ifWrap = document.getElementById('iframe-wrap');
                ifWrap.insertAdjacentHTML('beforeend', '<iframe src="'+host+url_part+ get_params+'"></iframe>');
            } else {
                body.insertAdjacentHTML('afterbegin', '<div class="dentist-iframe-wrap">' +
                    '<div class="dentist-iframe-container '+additional_class+'">' +
                    '<div class="dentist-header"></div>' +
                    '<a id="dentist-close" onclick=""></a>' +
                    '<div class="dentist-iframe-holder">' +
                    '<iframe src="'+host+url_part+ get_params+'"></iframe>' +
                    '<div class="dentist-close-frame"></div>' +
                    '</div></div></div>');
            }

        }

        document.getElementById('dentist-close').addEventListener("click", function () {
            var elements = document.getElementsByClassName('dentist-iframe-wrap');
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }
        });

    }
})();

